import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from "./../Store/store.js";
import { Container, Grid, Loader, Input, Image, Dropdown, Button } from "semantic-ui-react"
import web3Modal from "web3modal";
import Portis from "@portis/web3";

import grabNFT from '../Classes/grabNFT.js'
import MenuDisp from './menu.js';

import Web3Adapter from "./../Utils/web3Adapter.js";
import Api from '../Utils/apiAdapter.js';

const api = new Api();

function LegClaim(props) {

    const { store, actions } = useContext(StoreContext);
    let [mintButtonTxt, setMintButton] = useState("Mint Legs");
    let [mintButtonDisabled, setMintButtonDisabled] = useState(false);
	let [needsData, updateNeedsData] = useState(true);
	let [selectedIds, updateSelectedIds] = useState([]);
    let [totalCount, updateTotalCount] = useState(0);
    let [selectCount, updateSelectCount] = useState(0);
    let [update, forceUpdate] = useState(0);

    useEffect(() => {
        if (!store.web3Adapter) {
            connectWeb3();
        }else{
			if (!apiData.length === 0 || needsData) {
				getApiData()
			}
		}
    }, [store]);

    const connectWeb3 = async () => {
        const web3modal = new web3Modal({
            network: "mainnet",
            providerOptions: { }
        });
        const provider = await web3modal.connect();
        const web3Adapter = new Web3Adapter(provider);
        await web3Adapter.initialize();
        actions.addWeb3Adapter(web3Adapter);
        forceUpdate(update++);
    }

	const[apiData, setAPIData] = useState([]);
 
	const getApiData = async () => {
		if(needsData) {
			updateNeedsData(false);
            // get api data
			let response = await api.getLegArmNFTs(store.web3Adapter.selectedAddress)
            // set api data
			setAPIData(response);
            // get total count
            updateTotalCount(response.tokens.arms.length);
		}
	};
 
	const updateSelected = async (id) => {
        if(selectCount == 50) {
            return false;
        }
        if(selectedIds) {
            let tmpSelected = selectedIds;
            if(tmpSelected.includes(id)) {
                tmpSelected.splice(tmpSelected.indexOf(id),1);
                updateSelectCount(selectCount-1);
            }else{
                tmpSelected.push(id);
                updateSelectCount(selectCount+1);
            }
            updateSelectedIds(tmpSelected);
        }
	};
	
	const selectAll = async () => {
        if(apiData.tokens) {
            let selected = []
            let count = 0;
            apiData.tokens.arms.map((element, idx) => {
                if(count==50) {
                    return;
                }else{
                    selected.push(element["id"]);
                    count = count+1;
                }
            });
            updateSelectedIds(selected);
            updateSelectCount(count);
        }
	};
	
	const isSelected = (id) => {
        return selectedIds.includes(id);
	};
	
	const selectNone = async () => {
		updateSelectedIds([]);
        updateSelectCount(0)
	};
 
	const buildArms = () => {
        
		let colSet = [];
		let reset = false;
		let borderStyle = "";
		
		return apiData.tokens.arms.map((element, idx) => {
            
			if(reset) {
				colSet = [];
				reset = false;
			}

			let nftUrl = "/nft/arms/"+element["id"];
			let ipfsImgHash = "https://grabatars.com/images/arms/"+element["id"]+".png"

            borderStyle = "3px solid black";

            let isArmSelected = isSelected(element["id"]);

            if(isArmSelected) {
                borderStyle = "3px solid gold";
            }else{
                borderStyle = "3px solid black";
            }

			if(element["id"] == "1") {
				colSet.push(
					<Grid.Column>
					<p class="centered">
						<video onClick={() => { updateSelected(element["id"]); } } class="ui small centered image" style={{ border: borderStyle }}>
							<source src="/images/arms/1.mov"></source>
						</video>
						<span style={{ color:"gold", fontSize:"20px", fontFamily:"Lobster", display: "block" }}> { element["id"] } </span>
					</p>
					</Grid.Column>
				);
			}else{
				colSet.push(
					<Grid.Column>
					<p class="centered">
						<Image onClick={() => { updateSelected(element["id"]); } } src={ipfsImgHash} centered={true} size="small" style={{ border: borderStyle }} />
						<span style={{ color:"gold", fontSize:"20px", fontFamily:"Lobster", display: "block" }}> { element["id"] }</span>
					</p>
					</Grid.Column>
				);
			}

			if(((idx+1)%4 === 0) || apiData.tokens.arms.length-1 === idx ) {
				reset = true;
				return(<Grid columns={4} padded>{colSet}</Grid>)
			}

		});
		
	};

    const resetApiData = async () => {
        setAPIData({});
        updateNeedsData(true);
        getApiData();
    };

    const MintButton = async () => {
        // Make sure we are connected
        if (!store.web3Adapter) {
            return false;
        }
        // single claim
        if(selectCount == 1) {
            let mintTX = await store.web3Adapter.claimLeg(selectedIds[0]);
            if(mintTX) {
                resetApiData();
            }
        // multi-claim
        }else if(selectCount>1) {
            let mintTX = await store.web3Adapter.claimLegs(selectedIds);
            if(mintTX) {
                resetApiData();
            }
        // need at least 1 to mint
        }else{
            return false;
        }
    };

	const buildGallery = () => {

        if (!store.web3Adapter ) {

            return (
                <>
                    <Button size='massive' color="green" onClick={() => connectWeb3()}>Connect Wallet</Button>
                </>
            )

        }else{

			if(needsData) {
				getApiData();
			}

			if(!apiData.tokens) {
				return (null);
			}
			
			if(apiData.tokens.arms.length == 0) {
				return ( "You have no Grabatar Arm NFTs. Buy some on OpenSea!" );
			}

			return (
				<>

				<p class="centered">
                    <Button size='massive' color="green" disabled={mintButtonDisabled} onClick={() => MintButton()}> {mintButtonTxt} ({selectCount}) </Button>
                </p>
				
				<p class="centered">
					<Button size='large' color="green" onClick={() => selectAll()}>Select All ({totalCount})</Button>
					<Button size='large' color="red" onClick={() => selectNone()}>Select None</Button>
				</p>
				
				{ buildArms() }
				
				</>
			)

		}
	};

    return (
        <Grid fluid className="mainView">
            <MenuDisp states={{ ...props.states }}/>
            <Grid.Row centered>
                <Container>
                    <h1>Leg Claim</h1>
					{ buildGallery() }
                </Container>
            </Grid.Row>
        </Grid>
    );
}

export default LegClaim;
