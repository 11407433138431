import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from "./../Store/store.js";
import { Container, Grid, Loader, Input, Image, Dropdown, Button } from "semantic-ui-react"
import web3Modal from "web3modal";
import Portis from "@portis/web3";

import grabNFT from '../Classes/grabNFT.js'
import MenuDisp from './menu.js';

import Web3Adapter from "./../Utils/web3Adapter.js";
import Api from '../Utils/apiAdapter.js';

const api = new Api();

function MyGrabatars(props) {

    const { store, actions } = useContext(StoreContext);
	let [needsData, updateNeedsData] = useState(true);
    let [update, forceUpdate] = useState(0);

    useEffect(() => {
        if (!store.web3Adapter) {
            connectWeb3();
        }else{
			if (!apiData.length === 0 || needsData) {
				getApiData()
			}
		}
    }, [store]);

    const connectWeb3 = async () => {
        const web3modal = new web3Modal({
            network: "mainnet",
            providerOptions: { }
        });
        const provider = await web3modal.connect();
        const web3Adapter = new Web3Adapter(provider);
        await web3Adapter.initialize();
        actions.addWeb3Adapter(web3Adapter);
        forceUpdate(update++);
    }

	const[apiData, setAPIData] = useState([]);
 
	const getApiData = async () => {
		if(needsData) {
			updateNeedsData(false);
			let response = await api.getNFTsByAddress('getNFTsByAddress', store.web3Adapter.selectedAddress)
			setAPIData(response);
		}
	};
 
	const buildGallery = () => {
		
        if (!store.web3Adapter ) {
			
            return (
                <>
                    <Button size='massive' color="green" onClick={() => connectWeb3()}>Connect Wallet</Button>
                </>
            )
			
        }else{

			if(needsData) {
				getApiData();
			}

			if(!apiData.tokens) {
				return (null);
			}
			
			if(apiData.tokens.arms.length == 0) {
				return ( "You have no Grabatar NFTs. Buy some on OpenSea!" );
			}
			
			let colSet = [];
			let reset = false;

			return apiData.tokens.arms.map((element, idx) => {

				if(reset) {
					colSet = [];
					reset = false;
				}

				let nftUrl = "/nft/arms/"+element["id"];

				let ipfsImgHash = "https://grabatars.com/images/arms/"+element["id"]+".png"

				if(element["id"] == "1") {
					colSet.push(<Grid.Column><p class="centered"><a href={nftUrl}><video class="ui small centered image" style={{ border: "3px solid gold" }}><source src="/images/arms/1.mov"></source></video><span style={{ color:"gold", fontSize:"20px", fontFamily:"Lobster", display: "block" }}> { element["id"] } </span></a></p></Grid.Column>);
				}else{
					colSet.push(<Grid.Column><p class="centered"><a href={nftUrl}><Image src={ipfsImgHash} centered={true} size="small" style={{ border: "3px solid gold" }} /><span style={{ color:"gold", fontSize:"20px", fontFamily:"Lobster", display: "block" }}> { element["id"] } </span></a></p></Grid.Column>);
				}

				if(((idx+1)%4 === 0) || apiData.tokens.arms.length-1 === idx ) {
					reset = true;
					return(<Grid columns={4} padded>{colSet}</Grid>)
				}

			});

		}
	};

    return (
        <Grid fluid className="mainView">
            <MenuDisp states={{ ...props.states }}/>
            <Grid.Row centered>
                <Container>
                    <h1>My Grabatars</h1>
					{ buildGallery() }
                </Container>
            </Grid.Row>
        </Grid>
    );
}

export default MyGrabatars;
