import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";

import { StoreContext } from "./../Store/store.js";
import { Container, Grid, Loader, Input, Image, Dropdown, Button, Label } from "semantic-ui-react"

import grabNFT from '../Classes/grabNFT.js'
import MenuDisp from './menu.js';

import Api from '../Utils/apiAdapter.js';

const api = new Api();

function ViewNFT(props) {

	const { id } = useParams()

    const { store, actions } = useContext(StoreContext);
	
	let [needsData, updateNeedsData] = useState(true);
    let [update, forceUpdate] = useState(0);

    useEffect(() => {
		if (!apiData.length === 0 || needsData) {
			getApiData()
		}
    }, [store]);


	const[apiData, setAPIData] = useState([]);
 
	const getApiData = async () => {
		if(needsData) {
			updateNeedsData(false);
			let response = await api.getNFTsById('arms', id)
			setAPIData(response);
		}
	};
 
	const buildImg = (id) => {
		if(id == "1") {
			return (
				<p class="centered">
					<video class="ui small centered image" style={{ border: "3px solid gold" }}>
						<source src="/images/arms/1.mov"></source>
					</video>
				</p>
			)
		}else{
			let ipfsImgHash = "https://grabatars.com/images/arms/"+id+".png"
			return (
				<p class="centered">
					<Image src={ipfsImgHash} centered={true} size="small" style={{ border: "3px solid gold" }} />
				</p>
			)
		}
	};
	
	const buildAttributes = ( attributes ) => {

		return attributes.map((element, idx) => {
			return (
				<>
					<p><Label as='a' color='black'>{ element["trait_type"] } <Label.Detail>{ element["value"] }</Label.Detail></Label></p>
				</>
			)
		});

	};
 
	const buildNFT = () => {
		
		if(needsData) {
			getApiData();
		}

		if(!apiData.token && !apiData.error) {
			return (null);
			
		}else if(apiData.error) {
			return (
				<p>Invalid ID</p>
			)

		}else{
			
            let claimAvailable = "Yes";
            let claimClass = "greenBoiLobster";
            
			let tokenData = apiData.token;
			let tokenAttributes = apiData.token.attributes;			
            
			let openseaUrl = "https://opensea.io/"+tokenData["owner"];
			let openSeaBuyUrl = "https://opensea.io/assets/0xd448e6cca10ff5d1ce52ddc6b6fc4bfcb796d8eb/"+tokenData["id"];

            if(tokenData["isClaimed"]) {
                claimAvailable = "No";
                claimClass = "redBoiLobster";
            }
			
			return (
			
				<>
			
				<h1>Grabatar Arm #{ tokenData["id"] }</h1>

				{ buildImg(tokenData["id"]) }
				
				<Grid centered stackable columns={1}>
					<Grid.Column>
						<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
							<Grid.Column>
								<h3 style={{ marginTop: "25px", color: "#FFFFFF" }}>ID</h3>
							</Grid.Column>
							<Grid.Column>
								<p style={{ marginTop: "25px" }}>
									{ tokenData["id"] }
								</p>
							</Grid.Column>
						</Grid>
						<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
							<Grid.Column>
								<h3 style={{ marginTop: "25px", color: "#FFFFFF" }}>Type</h3>
							</Grid.Column>
							<Grid.Column>
								<p style={{ marginTop: "25px" }}>
									Arm
								</p>
							</Grid.Column>
						</Grid>
						<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
							<Grid.Column>
								<h3 style={{ marginTop: "25px", color: "#FFFFFF" }}>Owner</h3>
							</Grid.Column>
							<Grid.Column>
								<p style={{ marginTop: "25px" }}>
									<a href={ openseaUrl }>{ tokenData["owner"] }</a>
								</p>
							</Grid.Column>
						</Grid>
						<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
							<Grid.Column>
								<h3 style={{ marginTop: "25px", color: "#FFFFFF" }}>Attributes</h3>
							</Grid.Column>
							<Grid.Column>
								<p style={{ marginTop: "25px" }}>
									{ buildAttributes(tokenAttributes) }
								</p>
							</Grid.Column>
						</Grid>
						<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
							<Grid.Column>
								<h3 style={{ marginTop: "25px", color: "#FFFFFF" }}>Leg Claim Available</h3>
							</Grid.Column>
							<Grid.Column>
								<p style={{ marginTop: "25px" }}>
									<h3 className={claimClass} style={{ marginTop: "25px" }}> { claimAvailable } </h3>
								</p>
							</Grid.Column>
						</Grid>
						<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
							<Grid.Column>
								<h3 style={{ marginTop: "25px" }}>Buy Now</h3>
							</Grid.Column>
							<Grid.Column>
								<p style={{ marginTop: "25px" }}>
									<a href={openSeaBuyUrl}>
										<Button color="green" size="massive">OpenSea</Button>
									</a>
								</p>
							</Grid.Column>
						</Grid>
					</Grid.Column>
				</Grid>
				
				</>
				
			)
			
		}

	};

    return (
        <Grid fluid className="mainView">
            <MenuDisp states={{ ...props.states }}/>
            <Grid.Row centered>
                <Container>
					{ buildNFT() }
                </Container>
            </Grid.Row>
        </Grid>
    );
}

export default ViewNFT;
