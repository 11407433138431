import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom'

import './Styles/app.css';
import './Styles/crt.css'
import { Container, Dimmer, Loader, Grid } from 'semantic-ui-react';
import { Store } from './Store/store.js';

import MainView from './MainView.js';
import Wallet from './Components/wallet.js'

import Home from './Components/home.js'
import MenuDisp from './Components/menu.js'
import Footer from './Components/footer.js'
import Errors from "./Components/errors.js";

import TheLab from "./Components/thelab.js"
import Gallery from "./Components/gallery.js"
import MyGrabatars from "./Components/mygrabatars.js"
import ClubRewards from "./Components/clubrewards.js"
import Roadmap from "./Components/roadmap.js"
import Purchase from "./Components/purchase.js"
import DiscordVerify from "./Components/discord-verification.js"

import CashGrabNFT from "./Components/cash-grab.js"
import Grabatars from "./Components/grabatars.js"
import FreeClaim from "./Components/free-claim.js"
import LegClaim from "./Components/legs.js"
import ViewNFT from "./Components/view-nft.js"
import Provenance from "./Components/provenance.js"
import Rarity from "./Components/rarity.js"
import Puzzle from "./Components/puzzle.js"
import Mtn from "./Components/mtn.js"


import './App.css';

function App(props) {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [updateView, setUpdateView] = useState(0);

  const propStates = {
    isLoading,
    setLoading,
    isError,
    setError,
    updateView,
    setUpdateView
  }
  return (
  
    <Container fluid className="App" style={{ width: '100%' }}>
    
      <Grid className="App crt">
        <Store>
          <Router>
            {/*<Wallet states={propStates} />*/}
            <div id="outer-container">
              <div>
             
                <Grid.Row centered={true}></Grid.Row>

                <Dimmer page active={Boolean(isLoading)}>
                  <Loader>{String(isLoading)}</Loader>
                </Dimmer>

                <Errors states={propStates} />
                
                {/*<Grid.Row><MainView /></Grid.Row>*/}

                <Switch>
                  <Route exact path="/" render={(props) => <Home states={{ ...props }} />}/>
                  <Route exact path="/the-lab" render={(props) => <TheLab states={{ ...props }} />}/>
                  <Route exact path="/gallery" render={(props) => <Gallery states={{ ...props }} />}/>
                  <Route exact path="/my-grabatars" render={(props) => <MyGrabatars states={{ ...props }} />}/>
                  <Route exact path="/club-rewards" render={(props) => <ClubRewards states={{ ...props }} />}/>
                  <Route exact path="/roadmap" render={(props) => <Roadmap states={{ ...props }} />}/>
                  <Route exact path="/purchase" render={(props) => <Purchase states={{ ...props }} />}/>
                  <Route exact path="/discord-verification" render={(props) => <DiscordVerify states={{...props }} />}/>
                  <Route exact path="/free-claim" render={(props) => <FreeClaim states={{ ...props }} />}/>
                  <Route exact path="/gallery" render={(props) => <Gallery states={{ ...props }} />}/>
                  <Route exact path="/provenance" render={(props) => <Provenance states={{ ...props }} />}/>
                  <Route exact path="/rarity" render={(props) => <Rarity states={{ ...props }} />}/>
                  <Route exact path="/os92cty" render={(props) => <Mtn states={{ ...props }} />}/>
                  <Route exact path="/puzzle" render={(props) => <Puzzle states={{ ...props }} />}/>
				  <Route exact path="/legclaim" render={(props) => <LegClaim states={{ ...props }} />}/>
				  <Route exact path="/nft/arms/:id" render={(props) => <ViewNFT states={{ ...props }} />}/>
                </Switch>
              
              </div>
            </div>      
            <Footer></Footer>
          </Router>
         
        </Store>
      </Grid>
     
    </Container>

  );
}


export default App;
