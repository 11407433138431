import React from 'react';
import { Container, Divider, Grid, Button } from "semantic-ui-react";
import MenuDisp from './menu.js';

function Purchase(props) {

    return (
        <Grid fluid className="mainView">
            <MenuDisp states={{ ...props.states }}/>
            <Grid.Row centered>
                <Grid.Row centered>
                    <Container>
                        <Grid centered>
                            <Grid.Row centered>
                                <Grid.Column >

                                    <h2>Purchase</h2>

									<Grid centered stackable columns={1}>
										<Grid.Column>
											<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
												<Grid.Column style={{ color: '#118c4f' }} >
													<h2 style={{ marginTop: "25px" }}>Arms</h2>
													<p className="centered"><span className="greenboi">Fully minted!</span></p>
												</Grid.Column>
												<Grid.Column>
													<a href="https://opensea.io/collection/cashgrab">
														<Button style={{ marginTop: "40px" }} color="green" size="massive">Buy now on OpenSea!</Button>
													</a>
												</Grid.Column>
											</Grid>
											<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
												<Grid.Column style={{ color: '#118c4f' }} >
													<h2 style={{ marginTop: "25px" }}>Legs</h2>
												</Grid.Column>
												<Grid.Column>
													<p style={{ marginTop: "25px" }}>All Grabatar Arms will be able to claim a leg for free. Buy an arm on OpenSea to claim a leg.</p>
												</Grid.Column>
											</Grid>
											<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
												<Grid.Column style={{ color: '#118c4f' }} >
													<h2 style={{ marginTop: "25px" }}>Heads</h2>
												</Grid.Column>
												<Grid.Column>
													<p style={{ marginTop: "25px" }}>5,608 Grabatar Heads.</p>
												</Grid.Column>
											</Grid>
											<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
												<Grid.Column style={{ color: '#118c4f' }} >
													<h2 style={{ marginTop: "25px" }}>Grabatars</h2>
												</Grid.Column>
												<Grid.Column>
													<p style={{ marginTop: "25px" }}>Grabatars are constructed by burning a Grabatar Head, Arm, and Leg.</p>
												</Grid.Column>
											</Grid>
										</Grid.Column>
									</Grid>


                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>

            </Grid.Row>
        </Grid>
    );
}

export default Purchase;
