import React from 'react';
import { Grid, Menu } from "semantic-ui-react"
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function MenuDisp(props) {
  return (
    <>
    
    <Grid.Row centered>
        <h1 className="cglogotxt">Grabatars</h1>
    </Grid.Row>
    
    <Grid.Row centered className="ui grid">
    
      <Menu stackable pointing secondary centered>
        <Menu.Item name='home' as={Link} to='/' active={props.states.location.pathname.slice(1) === ''}>
          Home
        </Menu.Item>
        <Menu.Item name='the-lab' as={Link} to='/the-lab' active={props.states.location.pathname.slice(1) === 'the-lab'}>
          The Lab
        </Menu.Item>
        <Menu.Item name='gallery' as={Link} to='/gallery' active={props.states.location.pathname.slice(1) === 'gallery'}>
          Gallery
        </Menu.Item>
        <Menu.Item name='my-grabatars' as={Link} to='/my-grabatars' active={props.states.location.pathname.slice(1) === 'my-grabatars'}>
          My Grabatars
        </Menu.Item>
        <Menu.Item name='club-rewards' as={Link} to='/club-rewards' active={props.states.location.pathname.slice(1) === 'club-rewards'}>
          Club Rewards
        </Menu.Item>
        <Menu.Item name='roadmap' as={Link} to='/roadmap' active={props.states.location.pathname.slice(1) === 'roadmap'}>
          Roadmap
        </Menu.Item>
        <Menu.Item name='discord' as={Link} to='/discord-verification' active={props.states.location.pathname.slice(1) === 'discord-verification'}>
          Verify Discord
        </Menu.Item>
      </Menu>
      
    </Grid.Row>
    
    </>
  );
}

export default MenuDisp;
