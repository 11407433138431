import React, { createContext, Component } from 'react';

export const StoreContext = createContext();

// Class component for storing and updating shared states
export class Store extends Component {
    constructor() {
        super();
        this.state = {
            store: {
                web3Adapter: false,
            },
            actions: {
                addWeb3Adapter: web3Adapter => this.setState({ store: { ...this.state.store, web3Adapter: web3Adapter } }),
            }
        };
    }

    render() {
        return (
            <StoreContext.Provider value={this.state}>
                {this.props.children}
            </StoreContext.Provider>
        );
    }
}