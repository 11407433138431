import React from 'react';
import { Container, Grid, Loader, Input, Image } from "semantic-ui-react"
import MenuDisp from './menu.js';

function Mtn(props) {

    return (
        <Grid fluid className="mainView">
            
            <MenuDisp states={{ ...props.states }}/>
            
            <Container textAligned="center">
                
                <img src="https://i.imgur.com/5wLSwDb.png" />
                
            </Container>
            
        </Grid>
    );
}

export default Mtn;
