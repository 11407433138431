const axios = require('axios');

const Axios = axios.create({
	'Access-Control-Allow-Credentials': true
});

const API_URL = process.env.REACT_APP_API ? process.env.REACT_APP_API : 'https://grabatars.com/api/';

class Api {
	constructor() {
		this.options = {
			'timeout': 5000,
			'validateStatus':  function (status) {
				return true; // default
			},
		}
	}

	async get(route, data, page=0) {
		if (!route) {
			return false;
		}
		if (!data) {
			data = {};
		}

		let url = API_URL + route + "?filter=" + data + "&page="+page;

		let response = false;
		try {
			response = await Axios.get(url, this.options)
			if (!response || !response.data ) {
				throw 'Failed';
			}
			return response.data;
		} catch (ex) {
			return false;
		}
	}
  
	async getNFTsById(type, id) {
		let url = API_URL+"/getNFTsById?type="+type+"&id="+id;
		let response = false;
		try {
			response = await Axios.get(url, this.options)
			if (!response || !response.data ) {
				throw 'Failed';
			}
			return response.data;
		} catch (ex) {
			return false;
		}
	}

	async getLegArmNFTs(address) {
		let url = API_URL + "/getLegArmNFTs?address=" + address;
		let response = false;
		try {
			response = await Axios.get(url, this.options)
			if (!response || !response.data ){
				throw 'Failed';
			}
			return response.data;
		} catch (ex) {
			return false;
		}
	}
  
	async getNFTsByAddress(route, address) {
		if (!route) {
				return false;
		}

		let url = API_URL + route + "?address=" + address;

		let response = false;
		try {
			response = await Axios.get(url, this.options)
			if (!response || !response.data ){
				throw 'Failed';
			}
			return response.data;
		} catch (ex) {
			return false;
		}
	}

	async request(route, data) {
		if (!route) {
			return false;
		}
		if (!data) {
			data = {};
		}

		let url = API_URL + route;

		let response = false;
		try {
			response = await Axios.post(url, data, this.options)
			if (!response || !response.data ){//|| !response.data.data) {
			throw 'Failed';
			}
			return response.data;
		} catch (ex) {
			return false;
		}
	}
  
}

export default Api;
