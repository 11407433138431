const Web3 = require("web3");
const cashGrabABI = require("../ABI/CashGrab.json");
const legsABI = require("../ABI/Legs.abi.json");

class Web3Adapter {
    constructor(provider) {

        this.provider = provider;
        console.log(provider);
        this.web3 = false;

        // ABIs
        this.cgNFTABI = cashGrabABI;
        this.cgNFTABI = cashGrabABI;

        // contract
        this.cgNFTContract = false;
        this.legContract = false;
        this.totalSupply = 0;
        this.Max_NFT_Supply = 0;
        
        // mainnet
        this.cgNFTContractAddress = "0xd448E6CCA10ff5d1cE52Ddc6B6FC4bfCb796d8eb";
        this.legContractAddress = "0x187d25f6bbcc56468c3ceee23c2c99760160ae6a";
        
        // rinkeby
        // this.cgNFTContractAddress = "0x92f74D6DCb63282019770CfcB20a30063B97E9ba";
        // this.legContractAddress = "0xf65cD2a6712c281f8874B2F9e73bAE51975b1cfc";
        

        // TokenIDs
        this.baseTokenURI = false;

        this.accounts = false;
        this.selectedAddress = false;
        this.tokenBalances = {};
        this.NFTBalance = 0;

    }

    async initialize() {
        try {
            this.web3 = new Web3(this.provider);
            this.ethNetwork = await this.web3.eth.net.getId();
            // Make sure contracts are set up before init'ing
            if (!window || !window.ethereum) {
                throw "No web3 detected"
            }
            this.setupContractAddresses();

            // init contract
            this.cgNFTContract = new this.web3.eth.Contract(this.cgNFTABI, this.cgNFTContractAddress);
            this.legTokenContract = new this.web3.eth.Contract(legsABI, this.legContractAddress);

            this.accounts = await this.web3.eth.getAccounts();
            this.selectedAddress = this.accounts[0];

            // Get List of Tokens
            await this.getTokenBalance();
            await this.getNFTBalance();
            await this.getMaxSupply();
            await this.getTotalSupply();
        }
        catch (ex) {
            console.log(ex);
        }
    }

    setupContractAddresses = () => {
        /*
        if (window && window.ethereum && window.ethereum.networkVersion == "1") {
            this.cgNFTContractAddress = "0xd448E6CCA10ff5d1cE52Ddc6B6FC4bfCb796d8eb";

        }
        else {
            this.cgNFTContractAddress = "0xd448E6CCA10ff5d1cE52Ddc6B6FC4bfCb796d8eb";
        }
        */
        this.cgNFTContractAddress = "0xd448E6CCA10ff5d1cE52Ddc6B6FC4bfCb796d8eb";
    }

    async signMessage(msg) {
        try {
            let sig = await this.web3.eth.personal.sign(msg, this.selectedAddress, null);
            return sig;
        }
        catch (ex) {
            return false;
        }
    }

    async getTokenBalance(address) {

        let eth = await this.web3.eth.getBalance(this.selectedAddress);
        this.tokenBalances["eth"] = await this.web3.utils.fromWei(String(eth), "ether");
    }

    async getNFTBalance() {
        try{
            this.NFTBalance = await this.cgNFTContract.methods.balanceOf(this.selectedAddress).call();
        }
        catch(ex) {
            console.error(ex);
            return null;
        } 
    }

    async getTotalSupply() {
        try{
            this.totalSupply = await this.cgNFTContract.methods.totalSupply().call();
        }
        catch(ex) {
            console.error(ex);
            return 0;
        } 
    }

    async getMaxSupply() {
        try{
            this.Max_NFT_Supply = await this.cgNFTContract.methods.MAX_CASH_GRAB_NFTS().call();
        }
        catch(ex) {
            console.error(ex);
            return 0;
        } 
    }


    async getBaseTokenURI() {
        try{
            let baseTokenURI = await this.cgNFTContract.methods.baseURI().call();
            return baseTokenURI;
        }
        catch(ex) {
            console.error(ex);
            return "";
        } 
    }

    async getTokenURI(tokenID) {
        try{
            let tokenURI = await this.cgNFTContract.methods.tokenURI(tokenID).call();
            return tokenURI;
        }
        catch(ex) {
            console.error(ex);
            return "";
        } 
    }
    async purchase(purchaseAmount) {
        try {

            // Make sure we aren't buy more than 20 or less than 1
            if(purchaseAmount > 20)
                purchaseAmount = 20;
            else if(purchaseAmount < 1)
                purchaseAmount = 1;

            let ethValue = purchaseAmount * 25000000000000000;

            // Convert amountToPurchase to Hex
            let amountToPurchase = this.web3.utils.padLeft(purchaseAmount, 64);

            let gasPrice = await this.web3.eth.getGasPrice();
            // Gas Limit Estimate
            let gasAmount = await this.cgNFTContract.methods.purchase(amountToPurchase).estimateGas({ value: ethValue, from: this.selectedAddress });
            // Make TX
            await this.cgNFTContract.methods.purchase(amountToPurchase).send({ value: ethValue, gasPrice: gasPrice, gas: gasAmount, from: this.selectedAddress });
            return true
        } catch (ex) {
            console.error(ex);
            return false
        }
    }

    async checkSaleStatus() {
        try {
            let isSaleActive = await this.cgNFTContract.methods.saleIsActive().call();
            return isSaleActive;
        } catch (ex) {
            console.error(ex);
            return false
        }
    }
    
    async claimLeg(legId) {
        try {
            let ethValue = 0;
            let gasPrice = await this.web3.eth.getGasPrice();
            let gasAmount = await this.legTokenContract.methods.claimLeg(legId).estimateGas({ value: ethValue, from: this.selectedAddress });
            await this.legTokenContract.methods.claimLeg(legId).send({ value: ethValue, gasPrice: gasPrice, gas: gasAmount, from: this.selectedAddress });
            return true
        } catch (ex) {
            console.error(ex);
            return false
        }
    }
    
    async claimLegs(legIds) {
        try {
            let ethValue = 0;
            let gasPrice = await this.web3.eth.getGasPrice();
            let gasAmount = await this.legTokenContract.methods.claimXLegs(legIds).estimateGas({ value: ethValue, from: this.selectedAddress });
            await this.legTokenContract.methods.claimXLegs(legIds).send({ value: ethValue, gasPrice: gasPrice, gas: gasAmount, from: this.selectedAddress });
            return true
        } catch (ex) {
            console.error(ex);
            return false
        }
    }

}
export default Web3Adapter;
