import React from 'react';
import { Container, Divider, Grid} from "semantic-ui-react";
import MenuDisp from './menu.js';

function Roadmap(props) {

    return (
        <Grid fluid className="mainView">
            <MenuDisp states={{ ...props.states }}/>
            <Grid.Row centered>
                <Grid.Row centered>
                    <Container>
                        <Grid centered>
                            <Grid.Row centered>
                                <Grid.Column >

                                    <h2>Roadmap</h2>

									<Grid centered stackable columns={1}>
										<Grid.Column>
											<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
												<Grid.Column style={{ color: '#118c4f' }} >
													<h2 style={{ marginTop: "25px" }}>25%</h2>
													<p className="centered"><span className="greenboi">Completed!</span></p>
												</Grid.Column>
												<Grid.Column>
													<p style={{ marginTop: "25px" }}>Our team will begin to create the ultimate cash grab for our buyers. A puzzle with a <span className="greenboi">10 ethereum reward</span> created by the same team that solved OnePlus Crackables and MonteCrypto! (est. 1 month after milestone is hit)</p>
												</Grid.Column>
											</Grid>
											<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
												<Grid.Column style={{ color: '#118c4f' }} >
													<h2 style={{ marginTop: "25px" }}>50%</h2>
													<p className="centered"><span className="greenboi">Completed!</span></p>
												</Grid.Column>
												<Grid.Column>
													<p style={{ marginTop: "25px" }}>Begin constructing the Grabatar project. This begins with creating 5,608 unique Grabatar legs that will be freely claimable. Cash Grab NFT holders will be able to claim one leg per unique Cash Grab NFT. (Launch date pending artwork completion, est. 1–2 months after milestone is hit)</p>
												</Grid.Column>
											</Grid>
											<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
												<Grid.Column style={{ color: '#118c4f' }} >
													<h2 style={{ marginTop: "25px" }}>75%</h2>
													<p className="centered"></p>
												</Grid.Column>
												<Grid.Column>
													<p style={{ marginTop: "25px" }}>Build a collection of 5,608 Grabatar Heads.</p>
												</Grid.Column>
											</Grid>
											<Grid columns={2} style={{ paddingBottom: '20px' }} className="roadmapRow">
												<Grid.Column style={{ color: '#118c4f' }} >
													<h2 style={{ marginTop: "25px" }}>100%</h2>
													<p className="centered"></p>
												</Grid.Column>
												<Grid.Column>
													<p style={{ marginTop: "25px" }}>Build The Lab to construct Grabatars.</p>
												</Grid.Column>
											</Grid>
										</Grid.Column>
									</Grid>


                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>

            </Grid.Row>
        </Grid>
    );
}

export default Roadmap;
