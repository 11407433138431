import React from 'react';
import { Container, Divider, Grid, Image} from "semantic-ui-react";
import MenuDisp from './menu.js';

import GorillaHands from '../Images/gorillaDiamondHands.png'

import rewardVIP from '../Images/rewards-vip.png'
import rewardGrab from '../Images/rewards-grabatar.png'
import rewardGame from '../Images/rewards-game.jpg'
import rewardLab from '../Images/rewards-lab.png'
import rewardGive from '../Images/rewards-giveaways.png'
import rewardDiscord from '../Images/rewards-discord.png'
import rewardLegs from '../Images/rewards-legs.jpg'

function ClubRewards(props) {

    return (
        <Grid fluid className="mainView">
            <MenuDisp states={{ ...props.states }}/>
            <Grid.Row centered>
                <Grid.Row centered>
                    <Container>
                        <Grid centered>
                            <Grid.Row centered>
                                <Grid.Column >
                                    
                                    <h2>Club Rewards</h2>

                                    <Container>
                                        <p style={{ marginBottom: "25px"}}>Owning Grabatar NFTs comes with benefits and exclusive club rewards. Discover some of the rewards for members of the Grabatar community below.</p>
                                    </Container>

                                    <Grid centered stackable columns={2} className="section-spacing rewardsRow">
                                        <Grid.Column>
											<div className="rewardsBox">
												<h3>Verified Discord Membership</h3>
												<p>Access to the verified Grabatar owners only community channels and roles.</p>
											</div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Image centered={true} src={rewardDiscord} size="small" />
                                        </Grid.Column>
                                    </Grid>
                                    
                                    <div style={{height: "50px"}}></div>

                                    <Grid centered stackable columns={2} className="section-spacing rewardsRow">
                                        <Grid.Column>
                                            <Image centered={true} src={rewardGive} size="small" />
                                        </Grid.Column>
                                        <Grid.Column>
											<div className="rewardsBox">
												<h3>Member-only Giveaways</h3>
												<p>We have given away multiple eth worth of NFT drops to our members in the members only giveaway channel.</p>
											</div>
                                        </Grid.Column>
                                    </Grid>
                                    
                                    <div style={{height: "50px"}}></div>

                                    <Grid centered stackable columns={2} className="section-spacing rewardsRow">
                                        <Grid.Column>
											<div className="rewardsBox">
												<h3>10 ETH Puzzle Game</h3>
												<p>Explore the Grabatar Mansion in Volume 1 of the Cash Grab Mysteries. More puzzles and events in the future, such as scavenger hunts, raffles, and possibly a Volume 2!</p>
											</div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Image centered={true} src={rewardGame} size="small" />
                                        </Grid.Column>
                                    </Grid>

                                    <div style={{height: "50px"}}></div>

                                    <Grid centered stackable columns={2} className="section-spacing rewardsRow">
                                        <Grid.Column>
                                            <Image centered={true} src={rewardVIP} size="small" />
                                        </Grid.Column>
                                        <Grid.Column>
											<div className="rewardsBox">
												<h3>VIP Club</h3>
												<p>There are currently 3 VIP card holders, they are to receive a physical framed artwork of their VIP NFTs. More benefits coming soon to this exclusive club!</p>
											</div>
                                        </Grid.Column>
                                    </Grid>
                                    
                                    <div style={{height: "50px"}}></div>

                                    <Grid centered stackable columns={2} className="section-spacing rewardsRow">
                                        <Grid.Column>
											<div className="rewardsBox">
												<h3>Free Grabatar Legs</h3>
												<p>All Cash Grab NFTs will be able to claim Grabatar legs for free.</p>
											</div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Image centered={true} src={rewardLegs} size="small" />
                                        </Grid.Column>
                                    </Grid>
                                    
                                    <div style={{height: "50px"}}></div>

                                    <Grid centered stackable columns={2} className="section-spacing rewardsRow">
                                        <Grid.Column>
                                            <Image centered={true} src={rewardLab} size="small" />
                                        </Grid.Column>
                                        <Grid.Column>
											<div className="rewardsBox">
												<h3>Access to The Lab</h3>
												<p>Holders of the Grabatar Heads, Arms, and Legs will be able to combine them together into a full Grabatar.</p>
											</div>
                                        </Grid.Column>
                                    </Grid>

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>

            </Grid.Row>
        </Grid>
    );
}

export default ClubRewards;
