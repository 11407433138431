import React, { useState, useContext, useEffect } from 'react';
import { StoreContext } from "../Store/store.js";
import { Link } from "react-router-dom";
import { Button, Container, Divider, Grid, Progress, Image } from "semantic-ui-react";
import NumericInput from 'react-numeric-input';
import ReactPlayer from 'react-player'
import wizMov from '../Videos/wizard.mov'
import GorillaHands from '../Images/gorillaDiamondHands.png'
import BurnInGif from '../Images/Burn-In.gif'
import BurnInGif2 from '../Images/Burn-In2.gif'
import ThanosSnap from '../Images/ThanosSnap.gif'
import CashGrabGifNB from '../Images/CashGrab.gif'
import PeepoScribble from '../Images/PeepoScribble.png'
import PeepoDumzo from '../Images/PeepoDumzo.png'
import PeepoCash from '../Images/PeepoCash.png'
import PeepoHeist from '../Images/PeepoHeist.png'
import vogu from '../Images/vogu.jpg'
import CryptoPunk from '../Images/CryptoPunk.png'
import BoredApe from '../Images/BoredApe.png'
import AoR from '../Images/AoR.png'
import CoolCat from '../Images/coolcats.jpg'
import JosieArt from '../Images/josie.png'
import MetaKey from '../Images/Metakey.png'
import NeonDistrictKey from '../Images/NeonDistrictKey.png'
import Nhine from '../Images/NhineStreams.jpg'
import team_ziot from '../Images/josieyt.png'
import team_motive from '../Images/Motive.png'
import team_lefevre from '../Images/LeF_Cool_Cat.png'
import team_mattm from '../Images/supduck.png'
import team_jtobcat from '../Images/jtobape.png'
import MenuDisp from './menu.js';
import Wallet from './wallet.js'
import Api from '../Utils/apiAdapter.js';
const api = new Api();

function CashGrab(props) {
    const { store, actions } = useContext(StoreContext);
    const [purchaseAmount, setAmount] = useState(1);
    const [soldNFTs, setSoldAmount] = useState(0);
    const [totalNFTSupply, setMaxSupply] = useState(7777);
    const [purchaseButton, setPurchaseButton] = useState("Please Connect Wallet");
    const [purchaseDisabled, setPurchaseDisabled] = useState(true);

    const RevealDate = new Date(1629306000 * 1000).toDateString(); // August 18th 17:00 GMT 
    const SaleDate = new Date(1628096400  * 1000); // August 4th 17:00 GMT

    useEffect(() => {
        if (store.web3Adapter) {
            if (store.web3Adapter.Max_NFT_Supply != 0) {
                CheckPurchaseStatus();
            }
        }
       getProgressBar();
       setInterval(getProgressBar, 600000);
    }, [store]);

    const getProgressBar = async () => {
        let response = await api.request('totalsupply');
        if (!response.success) {
            return;
        }
        let totalSupply = response.success.totalSupply; 
        setSoldAmount(totalSupply);   
    };

    const startTimer = () => {
        if (SaleDate - Date.now() > 0) {         
            setInterval(getCountDown, 1000);
        }
        else{ // Sale and Claim are active
            return false;
        }
      }

    const getCountDown = () => {
        let timeRemaining = SaleDate - Date.now();
        let timeString = secondsToTime(timeRemaining / 1000);
        setPurchaseButton(timeString);
    }

    const secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        return hours+':'+minutes+':'+seconds+' until cash grabby';
    }

    const Purchase = async (amount) => {

        // Make sure we are connected
        if (!store.web3Adapter) {
            setPurchaseButton("Please Connect Wallet");
            return false;
        }

        // Request Send of TX
        let purchaseTX = await store.web3Adapter.purchase(amount);
        if (purchaseTX)
            setPurchaseButton("SUCCESSFULLY YOINKED.");
        else {
            setPurchaseButton("TRANSACTION FAILED.");
        }
    };

    const CheckPurchaseStatus = async () => {

        // Make sure we are connected
        if (!store.web3Adapter) {
            setPurchaseButton("Please Connect Wallet");
            return false;
        }

        let isSaleActive = await store.web3Adapter.checkSaleStatus();
        if (isSaleActive) {
            setPurchaseButton("GRAB SOME CASH (MINT)");
            setPurchaseDisabled(false);
        }
        else {
            // Get Time til sale
            if(!startTimer()){
                setPurchaseButton("SALE IS NOT ACTIVE");
            }
            setPurchaseDisabled(true);
        }
    };

    const createInfoDiv = () => {
        return (
            <Grid centered stackable columns={2} className="section-spacing">
                <Grid.Column>
                    <h1>This is our Cash Grab!</h1>
                    <p>Cash Grab is a NFT collection of 7,777 programmatically generated art.  Each NFT is randomly generated to contain an arm, an item, and a possiblity of having accessories such as rings and bracelets.</p>
                </Grid.Column>
                <Grid.Column>
                    <Grid columns={3}>
                        <Grid.Column>
                            <Image centered={true} src={BurnInGif} size="small" />
                        </Grid.Column>
                        <Grid.Column>
                            <Image centered={true} src={CashGrabGifNB} size="small" />
                        </Grid.Column>
                        <Grid.Column>
                            <Image centered={true} src={BurnInGif2} size="small" />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
        )
    };

    const createBuyDiv = () => {
        return (
            <>
            
                <Grid centered stackable columns={2} className="section-spacing">
                    <Grid.Column>
                        <h1> Grab these NFTs</h1>
                        <Image style={{ paddingBottom: '15px' }} centered={true} src={GorillaHands} size="medium" />
                    </Grid.Column>
                    <Grid.Column>
                        <h1>Get your Grab on</h1>
                        <p>Cash Grabs purchased after 8/11 will be revealed on 8/18 by the latest.</p>
                        <h3 style={{ fontSize: "24px" }}>0.025 Ethereum</h3>
                        <h3 style={{ textAlign: 'center' }}>Amount to Grab: <NumericInput style={{ input: { textAlign: "right" } }} size={1} min={1} max={20} value={purchaseAmount} onChange={(e) => { setAmount(e) }} /> (Max 20 per tx)</h3>
                        <Button disabled={purchaseDisabled} size='massive' style={{ marginTop: "20px" }} color="green" onClick={() => Purchase(purchaseAmount)}>{purchaseButton}</Button>
                    </Grid.Column>
                </Grid>
                            
                <hr />
                
                <Grid.Row>
                
                    <h1>GRAB FREE CASH</h1>
                    
                    <p>Note: This giveaway ended upon the sale going live, it is now over!</p>
                    
                    <p>The Cash Grab Devs believe in supporting the NFT community, and thats why we are giving away 200 free Cash Grabs. <br /> Owning any one of these tokens qualifies you to claim a <span className="cyphage">FREE CASH GRAB</span>.</p>
                    
                    <Grid centered container columns={3} style={{ padding: "25px" }}>
                        <Grid.Column>
                            <p className="centered nftPad">
                                <Image src={JosieArt} size="small" centered bordered className="nftImgs" /> <span className="nftText">Josie Art</span>
                            </p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="centered nftPad">
                                <Image src={CryptoPunk} size="small" centered bordered className="nftImgs" /> <span className="nftText">CryptoPunk</span>
                            </p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="centered nftPad">
                                <Image src={NeonDistrictKey} size="small" centered bordered className="nftImgs" /> <span className="nftText">Neon District Key</span>
                            </p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="centered nftPad">
                                <Image src={MetaKey} size="small" centered bordered className="nftImgs" /> <span className="nftText">Metakey</span>
                            </p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="centered nftPad">
                                <Image src={CoolCat} size="small" centered bordered className="nftImgs" /> <span className="nftText">Cool Cats</span>
                            </p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="centered nftPad">
                                <Image src={AoR} size="small" centered bordered className="nftImgs" /> <span className="nftText">Age of Rust: Origin</span>
                            </p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="centered nftPad">
                                <Image src={Nhine} size="small" centered bordered className="nftImgs" /> <span className="nftText">Nhinestreams Token</span>
                            </p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="centered nftPad">
                                <Image src={BoredApe} size="small" centered bordered className="nftImgs" /> <span className="nftText">Bored Ape</span>
                            </p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="centered nftPad">
                                <Image src={vogu} size="small" centered bordered className="nftImgs" /> <span className="nftText">Vogu Collective</span>
                            </p>
                        </Grid.Column>
                    </Grid>
                    
                    <p>Note: This giveaway ended upon the sale going live, it is now over!</p>
                    
                </Grid.Row>

            </>
        )
    };

    const createFairDiv = () => {
        return (
            <>
                <Grid.Row class="pad">
                    <h1>Provably Fair</h1>
                    <p>Each NFTs properties will be revealed on Wednesday - Aug 11, 2021 (or 24 hours after we sell).  Where the blockchain will decide the fate of each Grab.  Check out the <Link style={{ color: "#118c4f" }} to="/provenance">Provenance</Link> page for more details.</p>
                    <p><strong>Update:</strong> We performed a partial reveal for the first 4800 Cash Grabs on 8/11. We will do a final reveal on 8/18.</p>
                </Grid.Row>
            </>
        )
    };

    const createDigitsDiv = () => {
        return (
            <>
                <Grid.Row class="pad">
                    <h1>The Digits</h1>
                    <p><span style={{fontWeight: 'bolder', color: 'gold'}}>Uniqueness</span> - Each Cash Grab NFT is unique and has been programmatically generated to include one arm, one item and with the possiblity of up to three accessories.</p>
                    <br></br>
                    <p><span style={{fontWeight: 'bolder', color: 'gold'}}>Rarity</span> - Each category has it's own tiers of rarity, including how many accessories will be included on the Grab.</p>
                    <br></br>
                    <p><span style={{fontWeight: 'bolder', color: 'gold'}}>Decentralized</span> - All metadata including the images are stored on IPFS (Inter-planetary file system)</p>
                    <br></br>
                    <p><span style={{fontWeight: 'bolder', color: 'gold'}}>Exclusive Access</span> - Owning a Cash Grab NFT allows you access to the exclusive members-only channels in the Cash Grab Discord.  <Link style={{ color: "#118c4f" }} to="/discord-verification">Verify</Link> by signing a message with your wallet and Discord ID.</p>
                    <br></br>
                    <p><span style={{fontWeight: 'bolder', color: 'gold'}}>Royalty Free</span> - You own the full commerical rights to your Cash Grab NFTs.</p>
                    <br></br>
                    <p>VERIFIED SMART CONTRACT ADDRESS: <a target="_blank" href="https://etherscan.io/address/0xd448e6cca10ff5d1ce52ddc6b6fc4bfcb796d8eb#code" className="hash">0xd448E6CCA10ff5d1cE52Ddc6B6FC4bfCb796d8eb</a></p>
                </Grid.Row>
            </>
        )
    };


    const createRoadmapDiv = () => {
        return (
            <>

                <Grid centered container>
                    <Grid.Row class="pad">
                        <h2>ROADMAP</h2>
                    </Grid.Row>
                    <Grid.Row class="pad">
                        <Progress className="bar-width" size="large" value={soldNFTs} total={totalNFTSupply} progress='ratio' indicating>
                            <span className="limeyboi">Sold</span>
                        </Progress>
                    </Grid.Row>
                </Grid>

                <Grid centered stackable columns={2}>
                    <Grid.Column>
                        <Grid columns={2} style={{ paddingBottom: '20px' }} >
                            <Grid.Column style={{ color: '#118c4f' }} >
                                <h1>25%</h1>
                            </Grid.Column>
                            <Grid.Column>
                                <p>Our team will begin to create the ultimate cash grab for our buyers.  A puzzle with a <span className="greenboi">10 ethereum reward</span> created by the same team that solved OnePlus Crackables and MonteCrypto! The puzzle will likely launch a month after this milestone is hit.</p>
                            </Grid.Column>
                        </Grid>
                        <Grid columns={2} style={{ paddingBottom: '20px' }} >
                            <Grid.Column style={{ color: '#118c4f' }} >
                                <h1>50%</h1>
                            </Grid.Column>
                            <Grid.Column>
                                <p>Begin constructing the Cash Grab Grabatar project. This begins with creating 7,777 unique Cash Grab legs that will be freely claimable. Cash Grab holders will be able to claim one leg per unique Cash Grab NFT. (Launch date pending artwork completion)</p>
                            </Grid.Column>
                        </Grid>
                        <Grid columns={2} style={{ paddingBottom: '20px' }}>
                            <Grid.Column style={{ color: '#118c4f' }} >
                                <h1>75%</h1>
                            </Grid.Column>
                            <Grid.Column>
                                <p>Begin to work on the 7,777 Cash Grab Heads. (Launch date pending artwork completion)</p>
                            </Grid.Column>
                        </Grid>
                        <Grid columns={2} style={{ paddingBottom: '20px' }}>
                            <Grid.Column style={{ color: '#118c4f' }} >
                                <h1>100%</h1>
                            </Grid.Column>
                            <Grid.Column>
                                <p><del>24 hours after the REVEAL, we will perform a snapshot and send The Cash Grab Wizard to the largest holder of Cash Grabs.</del> <span className="greenboi">The Cash Grab Wizard has been claimed!</span></p>
                                <p>Start building the Grabatar smart contract, website, and artwork. (Launch date pending artwork completion)</p>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <ReactPlayer className="wizard" url={wizMov} playing={true} loop={true} muted={true} />
                    </Grid.Column>
                </Grid>
            </>
        )
    };



    const createFrankensteinDiv = () => {
        return (
            <>
                <h2>Cash Grab's Grabatars</h2>
                <p>The Cash Grab project starts with grabbing cash. Eventually, the grabby hands will need a full body. Once we get to 50% sales, we will begin to create a full set of 7,777 legs claimable by Cash Grab holders for free. Once we hit 75% sales, we will begin working on a release of 7,777 Cash Grab heads. Cash Grab holders will be able to use the website to combine an arm, leg, and head together to merge into a brand new Grabatar NFT based on the properties of the merged NFTs.</p>
            </>
        )
    };

    const createTeamDiv = () => {
        return (
            <>
                <h2>TEAM</h2>
                <p>Cash Grab is a <a href="https://www.cyphage.com/" className="cyphage">Cyphage</a> project, created by a group of friends looking to go beyond collecting NFTs and start creating.</p>
                <Grid centered columns={5}>
                    <Grid.Column centered={true}>
                        <p class="centered"><Image src={team_lefevre} className="teamImg" centered={true} size="small" /> <a href="https://twitter.com/_LeFevre_">LeFevre</a></p>
                    </Grid.Column>
                    <Grid.Column centered={true}>
                        <p class="centered"><Image src={team_ziot} className="teamImg" centered={true} size="small" /> <a href="https://www.twitter.com/bbuerhaus">ziot</a></p>
                    </Grid.Column>
                    <Grid.Column centered={true}>
                        <p class="centered"><Image src={team_motive} className="teamImg" centered={true} size="small" /> <a href="https://twitter.com/leemsparks">motive</a></p>
                    </Grid.Column>
                    <Grid.Column centered={true}>
                        <p class="centered"><Image src={team_jtobcat} className="teamImg" centered={true} size="small" /> <a href="https://twitter.com/JTobcat">jtobcat</a></p>
                    </Grid.Column>
                    <Grid.Column centered={true}>
                        <p class="centered"><Image src={team_mattm} className="teamImg" centered={true} size="small" /> <a href="https://twitter.com/mattmcquaig">mattm</a></p>
                    </Grid.Column>
                </Grid>
            </>
        )
    };

    return (
        <div className="ui">
            <MenuDisp states={{ ...props.states }}/>
            <Wallet states={{ ...props.states }} />
            
            <Container>
                    
                {createInfoDiv()}
                
                <hr id="grab" />
                
                {createBuyDiv()}
                
                <hr />
                
                {createFairDiv()}
                
                <hr />
                
                {createDigitsDiv()}
                
                <hr id="roadmap" />
                
                {createRoadmapDiv()}
                
                <hr />
                
                {createFrankensteinDiv()}
                
                <hr id="team" />
                
                {createTeamDiv()}

            </Container>
            
        </div>
    );
}

export default CashGrab;
