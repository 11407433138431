import React, { useState, useContext, useEffect } from 'react';
import { StoreContext } from "../Store/store.js";
import { Button, Container,  Grid, Input, Image, Segment } from "semantic-ui-react";
import MenuDisp from './menu.js';
import Wallet from './wallet.js';

import Api from '../Utils/apiAdapter.js';
const api = new Api();

function Puzzle(props) {
    const { store, actions } = useContext(StoreContext);
    const [puzzleSolution, setPuzzleSolution] = useState("");
    const [sigText, setSigText]= useState(false);

    useEffect(() => {
        if (store.web3Adapter) {
        }
    }, [store]);

    const SignMessage = async (puzzleSolution) => {
        let response = await api.request('sig-msg');
        if (!response) {
            return;
        }
        let messageToSign = "(" + response.success.msg + ") Signing this message to submit my puzzle answer as: " + puzzleSolution;
        console.log(messageToSign);
        let signatureMsg = await store.web3Adapter.signMessage(messageToSign)
        let validate = await api.request('puzzle-submit', { msg: messageToSign, signature: signatureMsg });
        if (validate.error !== undefined) {
            setSigText(validate.error);
            return;
        }
        if (validate.success !== undefined){
            setSigText(validate.success);
        }
    };

    return (

            <div class="ui">
                <MenuDisp states={{ ...props.states }}/>
                <Wallet states={{ ...props.states }} />
                <Grid.Row className="pad" centered>
                    <Container textAligned="center">
                        <h1>Puzzle Solution Submission</h1>  
                        <p>Enter the solution of the puzzle here, to get the password to the wallet (Lowercase) and sign the message to prove ownership of a Cash Grab NFT.</p>
                        <Input style={{paddingRight:"20px"}} placeholder="Enter Solution Here (lowercase)" onChange={(e) => { setPuzzleSolution(e.target.value) }}></Input>
                        <Button color="red" onClick={() => SignMessage(puzzleSolution)}>Sign Message</Button>
                        <p id="sigText">
                                {sigText ? sigText : ""}
                            </p>
                    </Container>
                </Grid.Row>
            </div>

    );
}

export default Puzzle;
