import React from 'react';
import { Grid, Image, Icon, Segment, Container } from "semantic-ui-react"
import CashGrabLogoGIF from '../Images/CashGlitch.gif'
import CashGrabLogo from '../Images/CashGrabLogo.png'

function Footer() {
    return (
        <Container className="footer">
            
            <hr></hr>
            
            <Grid centered columns={2}>
            
                <Grid.Column className="centered">
                    
                </Grid.Column>
                
                <Grid.Column className="centered">
                    <a href="https://twitter.com/TheGrabatars/" target="_blank" className="white">
                        <Icon name="twitter" size="huge" color="white" />
                    </a>

                    <a href="https://discord.gg/XEBYNB8PEk" target="_blank" className="white">
                        <Icon name="discord" size="huge" color="white" />
                    </a>
                    
                </Grid.Column>
                
            </Grid>

        </Container>
    );
}

export default Footer;