import React, { useContext } from 'react';
import { StoreContext } from "./Store/store.js";
import { Container, Grid, Segment } from "semantic-ui-react";

function MainView(props) {
  // Store component to access states
  //const { store, actions } = useContext(StoreContext);

  return (
    <Container fluid>
      <Segment>
        <Grid centered>
        </Grid>
      </Segment>
    </Container>
  )

}
export default MainView;