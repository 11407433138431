import React, { useState, useContext, useEffect } from 'react';
import { StoreContext } from "../Store/store.js";
import { Button, Container,  Grid, Input, Image, Segment } from "semantic-ui-react";
import DiscordLogo from '../Images/Discord-Logo+Wordmark-Color.png';
import MenuDisp from './menu.js';
import Wallet from './wallet.js';
import CryptoPunk from '../Images/CryptoPunk.png'
import BoredApe from '../Images/BoredApe.png'
import AoR from '../Images/AoR.png'
import CoolCat from '../Images/coolcats.jpg'
import JosieArt from '../Images/josie.png'
import MetaKey from '../Images/Metakey.png'
import vogu from '../Images/vogu.jpg'
import NeonDistrictKey from '../Images/NeonDistrictKey.png'
import Nhine from '../Images/NhineStreams.jpg'
import Api from '../Utils/apiAdapter.js';

const api = new Api();

function FreeClaim(props) {
    const { store, actions } = useContext(StoreContext);
    const [DiscordName, setDiscordName] = useState("");
    const [sigText, setSigText]= useState(false);

    useEffect(() => {
        if (store.web3Adapter) {
        }
    }, [store]);

    const SignMessage = async (DiscordName) => {
        let response = await api.request('sig-msg');
        if (!response) {
            return;
        }
        let messageToSign = "(" + response.success.msg + ") Signing this message to verify that my Discord Name is: " + DiscordName;
        console.log(messageToSign);
        let signatureMsg = await store.web3Adapter.signMessage(messageToSign)
        let validate = await api.request('sig-exchange-claim', { msg: messageToSign, signature: signatureMsg });
        if (validate.error !== undefined) {
            setSigText(validate.error);
            return;
        }
        if (validate.success !== undefined){
            setSigText("Your Free Claim has been recorded.");
        }
    };

    return (

            <div class="ui">
                <MenuDisp states={{ ...props.states }}/>
                <Wallet states={{ ...props.states }} />
                <Grid.Row className="pad" centered>
                    <Container textAligned="center">
                    
                        <h1>Free Claim Verification</h1>  
                        
                        <p>The Cash Grab Devs believe in supporting the NFT community, and thats why we are giving away 200 free Cash Grabs. <br /> Being a member of our Discord and owning any one of these tokens qualifies you to claim a <span className="cyphage">FREE CASH GRAB</span>.</p>
                        
                        <Grid centered container columns={3} style={{ padding: "25px" }}>
                            <Grid.Column>
                                <p className="centered nftPad">
                                    <Image src={JosieArt} size="small" centered bordered className="nftImgs" /> <span className="nftText">Josie Art</span>
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <p className="centered nftPad">
                                    <Image src={CryptoPunk} size="small" centered bordered className="nftImgs" /> <span className="nftText">CryptoPunk</span>
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <p className="centered nftPad">
                                    <Image src={NeonDistrictKey} size="small" centered bordered className="nftImgs" /> <span className="nftText">Neon District Key</span>
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <p className="centered nftPad">
                                    <Image src={MetaKey} size="small" centered bordered className="nftImgs" /> <span className="nftText">Metakey</span>
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <p className="centered nftPad">
                                    <Image src={CoolCat} size="small" centered bordered className="nftImgs" /> <span className="nftText">Cool Cats</span>
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <p className="centered nftPad">
                                    <Image src={AoR} size="small" centered bordered className="nftImgs" /> <span className="nftText">Age of Rust: Origin</span>
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <p className="centered nftPad">
                                    <Image src={Nhine} size="small" centered bordered className="nftImgs" /> <span className="nftText">Nhinestreams Token</span>
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <p className="centered nftPad">
                                    <Image src={BoredApe} size="small" centered bordered className="nftImgs" /> <span className="nftText">Bored Ape</span>
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <p className="centered nftPad">
                                    <Image src={vogu} size="small" centered bordered className="nftImgs" /> <span className="nftText">Vogu Collective</span>
                                </p>
                            </Grid.Column>
                        </Grid>
                        
                        <p>To claim a free Cash Grab NFT please enter your Discord Name including the numbers. (i.e. CashGrab#1234 ) and sign the message to verify you own the Ethereum Address that contains any of the special tokens. You must also be a member of the Discord.</p>
                        
                        <Input disabled style={{paddingRight:"20px"}} placeholder="Claim now closed." onChange={(e) => { setDiscordName(e.target.value) }}></Input>
                        
                        <Button color="red" Disabled={true}>Free Claim is now Closed.</Button>
                        
                        <p id="sigText" style={{fontSize: "22px", color: "red"}}>
                            {sigText ? sigText : ""}
                        </p>
                        
                    </Container>
                </Grid.Row>
            </div>

    );
}

export default FreeClaim;
