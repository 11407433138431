import React from 'react';
import { Button, Container, Divider, Grid, Segment, Icon, Image } from "semantic-ui-react"
import { Link } from "react-router-dom";

import CashGrabGif from '../Images/CashGrab.gif'
import GrabatarGif from '../Images/Grabatar.gif'

import CityNav from '../Images/citynav.png'

import team_ziot from '../Images/ziotape.jpg'
import team_lefevre from '../Images/LeF_Cool_Cat.png'

import works_grabatar from '../Images/work-grabatar.png'
import works_legs from '../Images/work-leg.png'
import works_heads from '../Images/work-head.png'
import works_arms from '../Images/work-arms.png'


import MenuDisp from './menu';

function Home(props) {

    return (
        <Grid fluid className="mainView">
        
            <MenuDisp states={{ ...props.states }}/>
            
            <Container textAligned="center">

                <Image inline src={CityNav} size="huge" />
                
                <Container text>

                    <p>Construct the ultimate Grabatar - collect and combine multiple NFTs into an epic one-of-a-kind avatar.</p>

                    <p>Grabatars are 5,609 composable NFTs comprised of heads, arms, and legs. Taking three of these NFTs into The Lab, collectors are able to combine multiple  NFTs to create a one-of-a-kind unique Grabatar. An economy created around scarcity with NFTs that get removed as Grabatars are made, until all of the Grabatars have been constructed. Join us and construct your ultimate Grabatar!</p>

                </Container>
                
                <hr />
                
                <h2>How it works</h2>

                <p style={{ padding: "50px" }}>The community minted 5,609 "Cash Grab" Arms, the initial foundation of the Grabatar NFTs. Each arm holder is able to mint and collect a free leg NFT. The Grabatar head collection will be sold in a sale soon. Once the head sale is done, the team will begin constructing The Lab, where Grabatars will be constructed. Once The Lab has been launched, Grabatar NFT holders will be able to combine a head, arm, and leg NFT to construct a randomized Grabatar comprised of traits submitted from the three NFTs consumed.</p>

                <Grid centered columns={4}>
                    <Grid.Column centered={true}>
                        <p class="centered"><Image src={works_arms} centered={true} size="small" /> <br /> <span class="fancy gold">Cash Grab Arms</span></p>
                    </Grid.Column>
                    <Grid.Column centered={true}>
                        <p class="centered"><Image src={works_heads} centered={true} size="small" /> <br /> <span class="fancy gold">Heads</span></p>
                    </Grid.Column>
                    <Grid.Column centered={true}>
                        <p class="centered"><Image src={works_legs} centered={true} size="small" /> <br /> <span class="fancy gold">Grabatar Legs</span></p>
                    </Grid.Column>
                    <Grid.Column centered={true}>
                        <p class="centered"><Image src={works_grabatar} centered={true} size="small" /> <br /> <span class="fancy gold">Grabatars</span></p>
                    </Grid.Column>
                </Grid>
                
                <hr />
                
                <h2>Buy Now</h2>

                <Grid.Row centered className="padBottom">
                    <Link id="shopInfo" to={"/purchase"}>
                        <Button size='massive' style={{ marginTop: "20px" }} color="green">Join the Club</Button>
                    </Link>
                </Grid.Row>
                
                <p class="centered" style={{ padding: "50px" }}>You can buy the first part of Grabatars, Cash Grab Arms, on Opensea!</p>
                
            </Container>
            
        </Grid>
    );
}

export default Home;
