const axios = require('axios');

class grabNFT {
    constructor(index, ownerAddress, ipfsJSONLink) {
        this.index = index;
        this.ownerAddress = ownerAddress;
        this.ipfsJSONLink = ipfsJSONLink;
        this.ipfsLink = false;
    }

    async initalize(){

        await this.getImageURL();
    }

    async getImageURL() {

        // Get Image URL
        await axios.get(this.ipfsJSONLink).then((response) => {   
            this.ipfsLink = "https://gateway.pinata.cloud/ipfs/QmT8qVaaeDReBCHpdEZQ82FhoYh6KmUmtdDFHgFFN6s5LT"        
            //this.ipfsLink = response.data.image;
        })
            .catch((err) => {
                console.log("Unable to get json.")
            })
    }
}
export default grabNFT;