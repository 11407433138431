import React from 'react';
import { Container, Divider, Grid} from "semantic-ui-react";
import MenuDisp from './menu.js';


function Rarity(props) {

    return (
        <Grid fluid className="mainView">
            <MenuDisp states={{ ...props.states }}/>
            <Grid.Row centered>
                <Grid.Row centered>
                    <Container>
                        <Grid centered>
                            <Grid.Row centered>
                                <Grid.Column >
                                    
                                    <h1>Cash Grab NFTs Rarity Listing</h1>
                                    
                                    <p>The Rarity Ranking is ready for the first ~4.8k items! We worked closely with the Rarity Sniper team to craft an accurate ranking. Keep in mind that this ranking will change as more get minted.</p>
                                    
                                    <hr />
                                    
                                    <p>Use the following command: <br /> !rarity cashgrab id [ID]</p>
                                    
                                    <p>Check your rank here: <br /> <a href="https://discord.gg/gGtvukQbqN">https://discord.gg/gGtvukQbqN</a></p>

                                    <hr />
                                    
                                    <p><a href="https://rarity.tools/">rarity.tools</a> coming soon</p>

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>

            </Grid.Row>
        </Grid>
    );
}

export default Rarity;
