import React from 'react';
import { Button, Container, Divider, Grid, Segment, Icon, Image } from "semantic-ui-react"
import { Link } from "react-router-dom";

import CashGrabGif from '../Images/CashGrab.gif'
import GrabatarGif from '../Images/Grabatar.gif'

import CityNav from '../Images/citynav.png'
import TheLabImg from '../Images/thelab.png'

import team_ziot from '../Images/ziotape.jpg'
import team_lefevre from '../Images/LeF_Cool_Cat.png'

import works_grabatar from '../Images/work-grabatar.png'
import works_legs from '../Images/work-leg.png'
import works_heads from '../Images/work-head.png'
import works_arms from '../Images/work-arms.png'

import MenuDisp from './menu';

function TheLab(props) {

    return (
        <Grid fluid className="mainView">
        
            <MenuDisp states={{ ...props.states }}/>
            
            <Container textAligned="center">

                <Image inline src={TheLabImg} style={{ border: "3px solid #ffd700" }} size="huge" />
                
                <Container text>

                    <p style={{ padding: "50px" }}>The Lab is where the Grabatars are born. To create a Grabatar, you must collect a Cash Grab arm, Grabatar Leg, and Grabatar Head NFT. Combining three of these NFTs will take traits from each and through mad science will result in a unique one-of-a-kind Grabatar. There are mysteries to unfold and unique Grabatars to discover...</p>

                </Container>

                <Grid centered columns={4}>
                    <Grid.Column centered={true}>
                        <p class="centered"><Image src={works_arms} centered={true} size="tiny" /> <br /> <span class="fancy gold">Cash Grab Arms</span></p>
                    </Grid.Column>
                    <Grid.Column centered={true}>
                        <p class="centered"><Image src={works_heads} centered={true} size="tiny" /> <br /> <span class="fancy gold">Heads</span></p>
                    </Grid.Column>
                    <Grid.Column centered={true}>
                        <p class="centered"><Image src={works_legs} centered={true} size="tiny" /> <br /> <span class="fancy gold">Grabatar Legs</span></p>
                    </Grid.Column>
                    <Grid.Column centered={true}>
                        <p class="centered"><Image src={works_grabatar} centered={true} size="tiny" /> <br /> <span class="fancy gold">Grabatars</span></p>
                    </Grid.Column>
                </Grid>
                
            </Container>
            
        </Grid>
    );
}

export default TheLab;
